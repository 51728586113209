import axios from "axios";

let token: string | null = null;

export const setToken = (t: string) => {
  token = t;
};

const axiosDefault = axios.create({
  withCredentials: false,
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    //@ts-ignore
    "Access-Control-Allow-Credentials": true,
  },
});

axiosDefault.interceptors.request.use(
  (request) => {
    if (token) {
      if (request.headers) request.headers["Authorization"] = token;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosDefault.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    //return Promise.reject(error);
  }
);

const get = async (url: string, params: any): Promise<any> => {
  const resp = await axiosDefault.get(url, { params });
  if (resp) {
    return resp.data;
  }
  return false;
};

const post = async (url: string, body: any): Promise<any> => {
  const resp = await axiosDefault.post(url, body);
  if (resp) {
    return resp.data;
  }
  return false;
};

const deleteApi = async (url: string): Promise<any> => {
  const resp = await axiosDefault.delete(url);
  if (resp) {
    return resp.data;
  }
  return false;
};

export { get, post, deleteApi };
