const AUTH = {
  initClient: "initClient",
  tokenVerify: "admin/verify-token",
  login: "admin/login",
  loginOTP: "admin/otp",
  resetPassword: "admin/reset-pin",
};

const PARTNERS = {
  updateStatus: "admin/update-status",
  getAllPartners: "admin/partners",
  deletePartner: "admin/delete/",
  restorePartner: "admin/restore/",
};

export { AUTH, PARTNERS };
