import * as api from "..";
import { AUTH } from "../config/endpoints";

interface AuthService {
  initClient: (body: { staticToken: string }) => Promise<any>;
  tokenVerify: (body: { token: string }) => Promise<any>;
  login: (body: {
    clientToken: string;
    emailAddress: string;
    password: string;
  }) => Promise<any>;
  loginOtp: (body: {
    otp: string;
    ott: string;
    clientToken: string;
  }) => Promise<any>;
  resetPassword: (body: { mobileNumber: string }) => Promise<any>;
}

const authService: AuthService = {
  initClient: async (body) => {
    const resp = await api.post(AUTH.initClient, body);
    return resp;
  },
  tokenVerify: async (params) => {
    const resp = await api.get(AUTH.tokenVerify, params);
    return resp;
  },
  login: async (body) => {
    const resp = await api.post(AUTH.login, body);
    return resp;
  },
  loginOtp: async (body) => {
    const resp = await api.post(AUTH.loginOTP, body);
    return resp;
  },
  resetPassword: async (body) => {
    const resp = await api.post(AUTH.resetPassword, body);
    return resp;
  },
};

export default authService;
