import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Nav, Navbar, NavbarBrand, Collapse } from "reactstrap";
import logolighttext from "../../../assets/images/logo-purple.png";
import profilephoto from "../../../assets/images/background/navImg.png";

const Header = () => {
  const history = useHistory();

  const logout = () => {
    localStorage.clear();
    history.push("/");
  };

  const showMobilemenu = () => {
    document.getElementById("main-wrapper").classList.toggle("show-sidebar");
  };

  return (
    <header className="topbar navbarbg" data-navbarbg="skin1">
      <Navbar className="top-navbar" dark expand="md">
        <div className="navbar-header" id="logobg" data-logobg="skin6">
          <NavbarBrand href="/">
            <span className="logo-text">
              <img src={logolighttext} alt="homepage" className="dark-logo" />
            </span>
          </NavbarBrand>
          <button
            className="btn btn-link nav-toggler d-block d-md-none"
            onClick={showMobilemenu}
          >
            <i className="fas fa-bars" />
          </button>
        </div>
        <Collapse className="navbarbg" navbar data-navbarbg="skin1">
          <Nav
            className="ml-auto mr-5 float-right"
            style={{ alignItems: "center" }}
            navbar
          >
            {localStorage.getItem("token") ? (
              <>
                {" "}
                <h5 className="mr-3 mb-0 text-primary">
                  <span className="font-weight-light text-muted">
                    Logged in as:{" "}
                  </span>{" "}
                  {localStorage.getItem("email")}
                </h5>
                <img
                  src={profilephoto}
                  alt="user"
                  className="rounded-circle"
                  width="31"
                />{" "}
                <h1 className="m-0 text-muted ml-2 font-weight-light">|</h1>
                <span className="font-weight-bold text-muted ml-2">Logout</span>
                <Link
                  to={"./login"}
                  onClick={logout}
                  className="logout-link ml-2"
                >
                  <i className=" fas fa-power-off" />
                </Link>
              </>
            ) : (
              ""
            )}
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  );
};
export default Header;
