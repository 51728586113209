import React, { createContext, useState } from "react";
export const AuthContext = createContext();

function AuthContextProvider({ children }) {
    const [userState, setUserState] = useState({
        loggedIn: false,
        token: "",
        id: "",
        email: "",
        mobile: "",
    });

    const setUser = (type, data) => {
        switch (type){
            case 'EMAIL' :
                setUserState({...userState, email: data})
            break;
            case 'TOKEN' :
                setUserState({...userState, token: data})
            break;
            case 'ID' :
                setUserState({...userState, id: data})
            break;
            case 'LOGGEDIN' :
                setUserState({...userState, loggedIn: data})
            break;
            case 'MOBILE' :
                setUserState({...userState, mobile: data})
            break;
            default :
                setUserState(data)
            break;
        }
    }
    return (
        <AuthContext.Provider
            value={{ userState, setUser }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;
