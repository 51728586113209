import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Loader from "../loader/loader";
import staffService from "../../api/services/staffService";
import ListItem from "./ListItem";

function UserList() {
  const [staffList, setStaffList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setModal] = useState(-1);
  const [showLoadMore, setShowLoadMore] = useState(false);

  useEffect(() => {
    onPageInit(0);
  }, []);

  const onPageInit = async (offset) => {
    const resp = await staffService.getAllPartners({
      limit: 40,
      offset: offset,
    });
    if (resp?.data?.length > 0) {
      const localData = offset === 0 ? resp.data : [...staffList, ...resp.data];
      setStaffList(localData);
      setIsLoading(false);
      setShowLoadMore(resp?.data?.length === 40);
    }
  };
  const toggle = () => setModal(-1);
  const onDeleteClick = (index) => {
    setModal(index);
  };
  const onDeleteModalClick = async () => {
    setIsLoading(true);
    await staffService.deletePartner({
      partnerID: staffList[isModalVisible].PARTNERID,
    });
    onPageInit(0);
    setModal(-1);
  };

  return (
    <Card>
      <Loader isLoading={isLoading} />
      <CardTitle className="border-bottom p-3 mb-0 font-weight-bold text-uppercase">
        <i class="fas fa-users-cog"></i>
        User Management
      </CardTitle>
      <CardBody>
        {/* <p className="text-secondary">
          Set up and manage administrative control of your business and stores
          and assign individual roles in each store. Click ADD USER to save
          changes and updates.
        </p> */}
        <table className="table stylish-table mb-0 mt-2 no-wrap v-middle">
          <thead>
            <tr>
              <th className=" font-weight-bold text-muted border-0 border-bottom">
                Name
              </th>
              <th className=" font-weight-bold text-muted border-0 border-bottom">
                Email address
              </th>
              <th className=" font-weight-bold text-muted border-0 border-bottom">
                Description
              </th>
              <th className=" font-weight-bold text-muted border-0 border-bottom">
                Give Back Percent
              </th>
              <th className=" font-weight-bold text-muted border-0 border-bottom">
                Status
              </th>
              <th className=" font-weight-bold text-muted border-0 border-bottom">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {staffList.map((user, index) => (
              <ListItem
                user={user}
                setStaffList={setStaffList}
                index={index}
                onDeleteClick={onDeleteClick}
              />
            ))}
          </tbody>
        </table>
        {showLoadMore && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: 8,
            }}
          >
            <Button
              color="primary"
              onClick={() => onPageInit(staffList.length)}
            >
              Load More
            </Button>
          </div>
        )}
      </CardBody>
      <Modal isOpen={isModalVisible > -1} toggle={toggle}>
        <ModalHeader toggle={toggle}>Delete User</ModalHeader>
        <ModalBody>Are you sure, you want to delete this user?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onDeleteModalClick}>
            Yes
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Card>
  );
}

export default UserList;
