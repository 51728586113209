import React from "react";
import { Row, Col } from "reactstrap";

import UserList from "../../components/userManager/UserList";

export default function UserManager() {
  return (
    <div>
      <Row>
        <Col sm={12}>
          <UserList />
        </Col>
      </Row>
    </div>
  );
}
